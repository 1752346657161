/***   BASE   ***/

.l-btn {
  font-family: $ff-aller;
  @include vw(
          $font-size: 30,
          $padding: 13 62,
          $border: 1px solid #ffeb5c,
          $margin-top: 54,
          $border-radius: 4
         // $left: 17
  );
  background-color: #ffeb5c;
  font-weight: bold;
  color: #000;
  position: relative;
  display: inline-block;
  transition: all .3s;
  text-decoration: none;
  &:hover,
  &:active,
  &:focus{
    transition: all .3s;
    background-color: #fff;
    color: #928e8e;
  }
  @include respond-to(small){
    left: 0;
  }
}

.l-padding{
  @include vw($padding: 150 0);
  @include respond-to(small){
    padding: 60px 0;
  };
}
.l-padding_bottom{
  @include vw($padding: 0 0 60);
}

/****  preloader   ******/
@-webkit-keyframes animsition-loading{
	0%{
		-webkit-transform:rotate(0deg);
		transform:rotate(0deg)
	}
	to{
		-webkit-transform:rotate(1turn);
		transform:rotate(1turn)
	}
}
@keyframes animsition-loading{
	0%{
		-webkit-transform:rotate(0deg);
		transform:rotate(0deg)
	}
	to{
		-webkit-transform:rotate(1turn);
		transform:rotate(1turn)
	}
}
.l-preload{
	background: #fff;
	position: fixed;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	width: 100%;
	height: 100%;
	z-index: 99999999;
}
.l-load{
	background-color: transparent;
	border-top: 5px solid rgba(0,0,0,.2);
	border-right: 5px solid rgba(0,0,0,.2);
	border-bottom: 5px solid rgba(0,0,0,.2);
	border-left: 5px solid #eee;
	-webkit-transform: translateZ(0);
	transform: translateZ(0);
	-webkit-animation-iteration-count: infinite;
	animation-iteration-count: infinite;
	-webkit-animation-timing-function: linear;
	animation-timing-function: linear;
	-webkit-animation-duration: .8s;
	animation-duration: .8s;
	-webkit-animation-name: animsition-loading;
	animation-name: animsition-loading;
	width: 32px;
	height: 32px;
	position: fixed;
	top: 50%;
	left: 50%;
	margin-top: -16px;
	margin-left: -16px;
	border-radius: 50%;
	z-index: 2;
	&:after{
		width: 32px;
		height: 32px;
		position: fixed;
		top: 50%;
		left: 50%;
		margin-top: -16px;
		margin-left: -16px;
		border-radius: 50%;
		z-index: 2;
	}
}
