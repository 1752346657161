.b-index {
  .b-hero {
    background: url('/images/bg_top.jpg') no-repeat;
    background-size: cover;
    background-position: left 0 top 0;
    position: relative;
    &:after{
      content: '';
      width: 100%;
      height: 100%;
      position: absolute;
      background-color: rgba(0, 0, 0, 0.5);
      top: 0;
      left: 0;
    }
    &_wrap {
      position: relative;
      z-index: 10;
      @include vw($padding: 388 0 392px 0);
      display: flex;
      justify-content: space-between;
      text-align: center;

      @include respond-to(small) {
        width: 100%;
        padding: 120px 0;
        flex-wrap: wrap;
      }

      @include respond-to(xs) {
        padding: 120px 0 60px;
      }

      &_content {
        color: #fff;
        margin: 0 auto;
        position: relative;
        @include vw(
                $max-width: 600,
               $left: 15
        );
        @include respond-to(small){
          left: 0;
        }
        h1 {
          font-family: $ff-book;
          font-weight: 600;
          @include vw(
                  $font-size: 56
          );
          @include respond-to(600){
            font-size: 30px;
          }
        }
        p {
          font-weight: 600;
          font-family: $ff-pt;
          color: #fff;
          @include vw(
                  $font-size: 32
          );
          @include respond-to(600){
            font-size: 20px;
          }
        }
        .l-btn {
          @include vw(
                  $margin-top: 54,
                  $left: 17
          );
          position: relative;
          @include respond-to(small){
            left: 0;
          }
        }
      }
    }
  }

  .b-how {
    @include vw(
      $padding: 150 0 186
    );
    @include respond-to(small){
      padding: 40px 0;
    }
    &_title{
      text-align: center;
      @include vw(
        $max-width: 810
      );
      margin: 0 auto;
      h2 {
        color: #000;
        text-align: center;
        font-family: $ff-medium;
        @include vw($font-size: 40,
        $margin-bottom: 30);
        @include respond-to(small) {
          font-size: 32px;
          margin-bottom: 20px;
        }
      }
      p{
        @include vw(
          $font-size: 26
        );
        line-height: 1.4;
      }
    }
    &_link{
      text-align: center;
      @include vw(
        $margin-top: 44
      );
      @include respond-to(small){
        margin-top: 0;
      }
      .l-btn{
        background-color: #3f5587;
        border-color: #3f5587;
        color: #fff;
        @include vw(
          $left: 21,
          $padding: 13 51
        );
        &:hover,
        &:focus,
        &:active{
          color: #3f5587;
          background-color: #fff;
        }
        @include respond-to(small){
          left: 0;
          margin-top: 0;
        }
      }
    }

    &_items {
      display: flex;
      justify-content: center;
      text-align: center;
      @include vw(
        $margin-top: 36
      );
      @include respond-to(small) {
        flex-wrap: wrap;
      }

    }
    &_item {
      width: 20%;
      @include vw($padding: 0 35);
      @include respond-to(small) {
        width: 50%;
        margin-bottom: 40px;
      }
      @include respond-to(430){
        width: 100%;
        margin-bottom: 10px;
      }
      &:last-child{
        @include respond-to(430){
          margin-bottom: 40px;
        }
      }
      &_icon {
        display: block;
        margin: 0 auto;
        @include vw($width: 235,
        $height: 235);
        @include respond-to(small) {
          width: 150px;
          height: 150px;
          background-size: 300px;
          margin-bottom: 15px;
        }
        @include respond-to(430){
          margin-bottom: 0;
        }

        &.first {
          background: url('/images/family-icon-final.png') no-repeat;
          background-size: cover;
        }
        &.sec {
          background: url('/images/mortgate-icon-final.png') no-repeat;
          background-size: cover;
        }
        &.third {
          background: url('/images/work-icon-final.png') no-repeat;
          background-size: cover;
        }
        &.fourth {
          background: url('/images/money-icon-final.png') no-repeat;
          background-size: cover;
        }
      }
      p {
        color: #464646;
        line-height: 1.2;
        font-family: $ff-book;
        @include vw($font-size: 26);
        @include respond-to(small) {
          br {
            display: none;
          }
        }
      }
    }
  }
  .b-about {
    background-color: #3f5587;
    background-size: cover;
    @include vw($padding: 148 0 126);
    text-align: center;
    @include respond-to(small) {
      padding: 40px 0;
    }
    &_content {
      margin: 0 auto;
      @include vw(
        $max-width: 1150
      );
      h2 {
        color: #fff;
        @include vw($font-size: 45,
        $margin-bottom: 50,
        $padding-left: 20);
        font-family: $ff-medium;
        line-height: 1.2;
        @include respond-to(small) {
          font-size: 32px;
          margin-bottom: 20px;
          padding-left: 0;
        }
      }
      p{
        color: #fff;
        line-height: 1.4;
        @include vw(
          $font-size: 26,
          $margin-bottom: 30
        );
      }
    }
  }

  .b-ready{
    background-image: url("/images/jacob-culp.jpg");
    background-size: cover;
    @include vw($padding: 334 0 251);
    text-align: center;
    position: relative;
    background-position: bottom 0 center;
    &:after{
      content: '';
      width: 100%;
      height: 100%;
      position: absolute;
      background-color: rgba(0, 0, 0, 0.4);
      top: 0;
      left: 0;
    }
    &_wrap{
      position: relative;
      z-index: 10;
      @include vw(
        $max-width: 800
      );
      margin: 0 auto;
      h2{
        color: #fff;
        @include vw($font-size: 58,
        $margin-bottom: 50,
        $padding-left: 20);
        font-family: $ff-medium;
        line-height: 1.2;
        @include respond-to(small){
          padding-left: 0;
          font-size: 32px;
        }
      }
    }
    &_link{
      text-align: center;
      .l-btn{
        @include vw(
          $margin-top: 9,
          $left: 28
        );
        @include respond-to(small){
          left: 0;
        }
      }
    }
  }
}

