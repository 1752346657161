 //Базовая ширина сайта. При данном размере сайт будет смотреться 1 в 1 как в psd макете при vw размерах
 /*$siteWidth: 1920;*/
 $siteWidth: 2000;
 
 //Точки изменения размеров
 $breakpoints: (
    '1200' : 1.3,
 	'900': 1.5,
 	
 );
 
 // Получаем размер элемента в vw
 // $px - пиксели
 // $multi - Множитель, берется из $breakpoins
 @function get-vw($px, $multi:1) {
 	@return $px/$siteWidth*100*$multi;
 }
 
 // Генерируем массив с размерами исходя из $breakpoins
 // $values параметр или параметры элемента (ex margin: 10 auto 20)
 // $multi - Множитель, берется из $breakpoins
 // @return - Возращаемый размер, тип
 @function generateVw($values, $multi:1) {
 	$vw: ();
 	$px: ();
 	@each $value in $values {
		@if type-of($value) != 'number' {
 			$px: append($px , $value);
 			$vw: append($vw, $value);
 		}
 		@else {
 			$val: parseInt($value);
 			$px: unquote('#{append($px,  $val*$multi)}px');
 			$vw: unquote('#{append($vw,  get-vw($val, $multi))}vw');
 		}
 	}
 	@return ('vw':$vw, 'px':$px);
 }
 
 // Приведение типов к int
 @function parseInt($n) {
 	@return $n / ($n * 0 + 1);
 }