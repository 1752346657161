//Color
$brand: #3f5587;
$grey: #eeeeee;
$text: #464646;
$yellow: #ffeb5c;
/*
==============================
Fonts
==============================
*/

$ff: "AvenirOblique", sans-serif;
$ff-book: "AvenirBook", sans-serif;
$ff-medium: "AvenirMedium", sans-serif;
$ff-bitter: "Bitter", sans-serif;
$ff-aller: "Aller", sans-serif;
$ff-pt: "PT", sans-serif;
