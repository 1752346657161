@import "varibles";

/*
==============================
fonts
==============================
*/

@font-face {
  font-family: 'Bitter';
  src: url('/fonts/bitter/Bitter-Regular.otf') format('opentype');
  font-weight: normal;
}

@font-face {
  font-family: 'AvenirOblique';
  src: url('/fonts/avenir/AvenirLTStd-Oblique.otf') format('opentype');
  font-weight: normal;
}

@font-face {
  font-family: 'AvenirMedium';
  src: url('/fonts/avenir/AvenirLTStd-Medium.otf') format('opentype');
  font-weight: normal;
}

@font-face {
  font-family: 'AvenirBook';
  src: url('/fonts/avenir/AvenirLTStd-Book.otf') format('opentype');
  font-weight: normal;
}

@font-face {
  font-family: 'Aller';
  src: url('/fonts/aller/Aller_Rg.ttf') format('truetype');
  font-weight: normal;
}

@font-face {
  font-family: 'PT';
  src: url('/fonts/pt/pt-serif.ttf') format('truetype');
  font-weight: normal;
}

@font-face {
  font-family: 'Lato', sans-serif;
  src: url('/fonts/lato/Lato-Bold.ttf') format('truetype');
  font-weight: normal;
}

body {
  @include vw($font-size: 36);
  font-family: $ff-pt;
  color: #000;
  background-color: #fff;
  line-height: 1.6;
  @include respond-to(small) {
    font-size: 20px;
  }
;
}

p {
  @include vw($font-size: 36);
  font-family: $ff-pt;
  color: #000;
  line-height: 1.6;
  @include respond-to(small) {
    font-size: 20px;
  }
;
}

a {
  color: $brand;
  text-decoration: none;
  transition: all 0.3s ease-in;
  &:hover, &:active, &:focus {
    color: $text;
    text-decoration: none;
  }
}

img {
  max-width: 100%;
}

sup {
  font-size: 60%;
}

.g-site_width {
  @include vw($max-width: 1620,
  $padding: 0 15);
  width: 100%;
  margin: 0 auto;
  @include respond-to(small) {
    padding: 0 15px;
  }
;
}

/**  Header  **/
.g-header {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 100;
  @include vw($padding: 28 0 0);
  .g-site_width {
    @include vw($max-width: 1900);
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  &_logo {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    @include vw(
            $padding-left: 33
    );
    @include respond-to(small) {
      padding-left: 0;
    }
    img {
      @include vw($width: 110);
      max-width: 100%;
      @include respond-to(small) {
        width: 50px;
      }
    ;
    }
    span {
      @include vw($font-size: 42,
      $margin-left: 20);
      font-family: $ff-bitter;
      color: #fff;
      @include respond-to(small) {
        font-size: 30px;
      }
    ;
    }

  }
  &_icon {
    display: none;
  }
  nav {
    @include vw(
            $padding-right: 70
    );
    a {
      color: #fff;
      @include vw(
              $font-size: 20,
              $margin-left: 46
      );
      font-family: 'Lato', sans-serif;
      &:first-child {
        margin-left: 0;
      }
      &:hover,
      &:active,
      &:focus {
        color: #ffeb5c;
      }
    }
  }
}

.g-footer {
  background: #000;
  @include vw($padding: 100 0 20);
  @include respond-to(small) {
    padding: 60px 0 20px;
  }
  &_contact {
    display: flex;
    justify-content: space-around;
    color: #fff;
    @include respond-to(small) {
      flex-wrap: wrap;
      justify-content: space-between;
    }
    &_item {
      width: 30%;
      text-align: center;
      line-height: 1.2;
      @include respond-to(small) {
        width: 33%;
        margin-bottom: 30px;
      }
      @include respond-to(600) {
        width: 100%;
      }
      @include vw($padding: 0 30);
      a {
        color: #fff;
        font-family: $ff-medium;
        @include vw($font-size: 23);
        line-height: 1.2;

        i {
          display: inline-block;
          margin: 0 auto;
          @include vw($font-size: 33,
          $margin-bottom: 30);
        }
        &:hover, &:active, &:focus {
          opacity: 0.5;
        }
      }
    }

  }
  &_links {
    line-height: 1;
    text-align: center;
    @include vw(
            $margin-top: 100,
            $padding: 20 0
    );
    a {
      text-decoration: none;
      font-family: $ff-medium;
      color: #fff;
      @include vw(
              $font-size: 23,
              $margin: 0 20
      );
      &:hover,
      &:active,
      &:focus {
        text-decoration: underline;
      }
    }
  }
  p {
    text-align: center;
    color: #fff;
    font-family: $ff-medium;
    @include vw(
            $font-size: 23
    );
  }
  &_bottom_text {
    @include vw(
            $padding: 40 0
    );
    @include respond-to(small) {
      padding: 30px 0 0;
    }
  }
}

//article
.g-style {
  font-family: $ff-medium;
  @include vw(
          $padding: 60 0
  );
  h1 {
    text-align: center;
    font-weight: 600;
    @include vw(
            $font-size: 40,
            $margin: 0 0 40
    );
  }
  h2, h3 {
    font-weight: 600;
    @include vw(
            $font-size: 30,
            $margin: 0 0 20
    );
  }
  h2{
    @include vw(
            $font-size: 36
    );
  }
  p {
    font-family: $ff-medium;
    color: #555;
    @include vw(
            $font-size: 24,
            $margin: 0 0 20
    );
  }
  ul {
    font-family: $ff-medium;
    color: #555;
    list-style-type: disc;
    @include vw(
            $font-size: 24,
            $margin: 0 0 20,
            $padding-left: 50
    );
    li{
      line-height: 1.6;
    }

  }
  a{
    color: #35b1ef;
    &:hover,
    &:focus,
    &:active{
      text-decoration: underline;
    }
  }
}

/*
  media
 */

@media(max-width: 768px) {
  .g-header {
    display: flex;
    align-items: center;
    background-image: none;
    .g-site_width {
      justify-content: space-between;
      align-items: center;
    }
    nav {
      display: none;
      a {
        margin-right: 0;
        border: none;
        display: block;
        font-size: 18px;
        padding: 15px;
        font-weight: 600;
        border-bottom: 1px solid #000;
        color: #000;
        margin-left: 0;

        &:hover {
          background: #e4e4e4;
          opacity: 1;
          color: #000;
        }
      }
      &.open {
        z-index: 1;
        background-color: #fff;
        display: block;
        width: 100%;
        min-width: 100%;
        top: 80px;
        left: 0;
        margin-left: 0;
        text-align: center;
        position: absolute;
        min-height: 100%;
        overflow-y: auto;
        padding-right: 0;
      }

    }
    & &_icon {
      display: block;
      width: 30px;
      height: 25px;
      position: relative;
      margin: 11px 10px;
      -webkit-transform: rotate(0deg);
      -moz-transform: rotate(0deg);
      -o-transform: rotate(0deg);
      transform: rotate(0deg);
      -webkit-transition: .5s ease-in-out;
      -moz-transition: .5s ease-in-out;
      -o-transition: .5s ease-in-out;
      transition: .5s ease-in-out;
      cursor: pointer;
      float: right;
    }
    /** Icon 2 **/
    &_icon span {
      display: block;
      position: absolute;
      height: 3px;
      width: 50%;
      background: #ffeb5c;
      opacity: 1;
      -webkit-transform: rotate(0deg);
      -moz-transform: rotate(0deg);
      -o-transform: rotate(0deg);
      transform: rotate(0deg);
      -webkit-transition: .25s ease-in-out;
      -moz-transition: .25s ease-in-out;
      -o-transition: .25s ease-in-out;
      transition: .25s ease-in-out;
    }

    &_icon span:nth-child(even) {
      left: 50%;
    }

    &_icon span:nth-child(odd) {
      left: 0;
    }

    &_icon span:nth-child(1), &_icon span:nth-child(2) {
      top: 0;
    }

    &_icon span:nth-child(3), &_icon span:nth-child(4) {
      top: 11px;
    }

    &_icon span:nth-child(5), &_icon span:nth-child(6) {
      top: 22px;
    }

    &_icon.open span:nth-child(1), &_icon.open span:nth-child(6) {
      -webkit-transform: rotate(45deg);
      -moz-transform: rotate(45deg);
      -o-transform: rotate(45deg);
      transform: rotate(45deg);
    }

    &_icon.open span:nth-child(2), &_icon.open span:nth-child(5) {
      -webkit-transform: rotate(-45deg);
      -moz-transform: rotate(-45deg);
      -o-transform: rotate(-45deg);
      transform: rotate(-45deg);
    }

    &_icon.open span:nth-child(1) {
      left: 5px;
      top: 9px;
    }

    &_icon.open span:nth-child(2) {
      left: calc(50% - 5px);
      top: 9px;
    }

    &_icon.open span:nth-child(3) {
      left: -50%;
      opacity: 0;
    }

    &_icon.open span:nth-child(4) {
      left: 100%;
      opacity: 0;
    }

    &_icon.open span:nth-child(5) {
      left: 5px;
      top: 14px;
    }

    &_icon.open span:nth-child(6) {
      left: calc(50% - 5px);
      top: 14px;
    }

  }
}